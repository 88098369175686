import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "toast"
    }}>{`Toast`}</h1>
    <p>{`Push notifications to your visitors with a toast, a lightweight and easily customizable alert message.
Toast works as a reactive service`}</p>
    <div className='box'>
  <strong>Attention</strong>: the use of the components needs the <span className='pink'>squid.js</span> included in the project
    </div>
    <div className='box'>
  <strong>Attention + 1</strong>: Probably the interaction via Javascript should not work well in SPA applications needs some implementation to acess Toast on window element
    </div>
    <h2 {...{
      "id": "exemple"
    }}>{`Exemple`}</h2>
    <button type='button' className='button inverted button-black ' onClick={() => Toast.show("Hello world", {
      closeButton: true,
      position: "top right",
      className: ""
    })}>
  Click here
    </button>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<button
  type='button'
  on-click='() => Toast.show("Hello world", { closeButton: true, position: "top right", className: "" })'
>
  Click here
</button>
`}</code></pre>
    <h2 {...{
      "id": "types"
    }}>{`Types`}</h2>
    <p>{`Toast has standard types, to call like `}<inlineCode parentName="p">{`Toast.success(...)`}</inlineCode>{`, `}<inlineCode parentName="p">{`Toast.error(...)`}</inlineCode>{`, etc... They are:`}</p>
    <pre><code parentName="pre" {...{}}>{`show
inverted
success
warning
error
grayscale
custom
theme
`}</code></pre>
    <h2 {...{
      "id": "position"
    }}>{`Position`}</h2>
    <p>{`Combine these values on `}<inlineCode parentName="p">{`position`}</inlineCode>{` attribute`}</p>
    <pre><code parentName="pre" {...{}}>{`top
bottom
left
right
`}</code></pre>
    <h2 {...{
      "id": "classname"
    }}>{`ClassName`}</h2>
    <p><inlineCode parentName="p">{`className`}</inlineCode>{` accepet a string that add a class to tooltip container on show it`}</p>
    <h2 {...{
      "id": "closebutton"
    }}>{`CloseButton`}</h2>
    <p>{`Boolean value to show or not close button. Default `}<inlineCode parentName="p">{`closeButton: true`}</inlineCode></p>
    <h2 {...{
      "id": "full"
    }}>{`Full`}</h2>
    <p>{`To Toast use `}<inlineCode parentName="p">{`100vw`}</inlineCode>{` add `}<inlineCode parentName="p">{`full`}</inlineCode>{` to `}<inlineCode parentName="p">{`className`}</inlineCode>{` attribute`}</p>
    <h2 {...{
      "id": "onclick"
    }}>{`onClick`}</h2>
    <p>{`Pass `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` (with a function) atribute combinated with `}<inlineCode parentName="p">{`notOverClick: true`}</inlineCode>{` to do a custom action click`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      